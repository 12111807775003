import React, {useContext, useState} from "react";
import {Button, Grid, Hidden, IconButton, Tooltip} from "@material-ui/core";
import {AwesomeQR} from "@awesomeqr/react";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles, useTheme} from "@material-ui/core/styles";

import DialogStand from "./DialogStand";
import Alert from "../../utils/Alert";

import ConfigContext from "../../context/config.context";
import ActionContext from "../../context/action.context";

import logo from '../../assets/img/logo.png'
import {
	CheckCircleRounded,
	CreateRounded,
	DeleteRounded,
	GetAppRounded,
	ScheduleRounded,
	VisibilityRounded
} from "@material-ui/icons";
import DialogNotif from "../Notifications/DialogNotif";
const typeText = {
	sound: "son",
	markdown: "Texte",
	video: "vidéo"
}

export default function StandRow({stand}){
	const theme = useTheme()
	const {config} = useContext(ConfigContext)
	const {deleteStand} = useContext(ActionContext)

	const [modalIsOpen, setModalIsOpen] = useState(false)

	const downloadQrCode = () => {
		function downloadBlob(dataUrl, name = 'file.png') {
			const link = document.createElement('a');
			link.href = dataUrl;
			link.download = name;

			// this is necessary as link.click() does not work on the latest firefox
			link.dispatchEvent(
				new MouseEvent('click', {
					bubbles: true,
					cancelable: true,
					view: window
				})
			);

			setTimeout(() => {
				// For Firefox it is necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(dataUrl);
				link.remove();
			}, 100);
		}

		new AwesomeQR({
			components: {
				data: {
					scale: 1,
				},
				timing: {
					scale: 0.5,
					protectors: false,
				},
				alignment: {
					scale: 0.5,
					protectors: false,
				},
				cornerAlignment: {
					scale: 0.5,
					protectors: true,
				},
			},
			text: `${config.qr.baseUrl}/index.html?standId=${stand.nfcId}`,
			size: 500,
		}).draw().then((dataURL) => {
			downloadBlob(dataURL, `qrcode-${stand.name}.png`);
		});
	}

	const handleDelete = () => {
		Alert.warningDelete().then(()=>{deleteStand(stand)})
	}

	const useStyles = makeStyles(() => ({
		tableRow: {
			"& td" : {
				paddingTop: "0.25rem",
				paddingBottom: "0.25rem",
			}
		},
	}))
	const classes = useStyles();

	return(
		<Grid item xs={12} style={{background : "transparent", borderBottom : "#e5ddcc 1px solid"}}>
			<Grid container style={{padding : "15px"}}>
				<Grid item xs={4} md={2} className={classes.rowContainer}>
					<p className={classes.rowText}>
						{stand.name}
					</p>
				</Grid>
				<Hidden smDown>
					<Grid item xs={0} md={2} className={classes.rowContainer}>
						<p className={classes.rowText}>
							{stand.nfcId}
						</p>
					</Grid>
					<Grid item xs={0} md={2} className={classes.rowContainer}>
						<p className={classes.rowText}>
							{typeText[stand.type][0].toUpperCase() + typeText[stand.type].slice(1)}
						</p>
					</Grid>
					<Grid item xs={0} md={4} className={classes.rowContainer}>
						<p className={classes.rowText}>
							{stand.type === "sound" &&
							<audio
								style={{marginTop: "0.75rem", maxWidth : "100%"}}
								controls
								src={`${config.storage.baseUrl}${stand.resFile.find(resData=>resData.langCode === "fr").file}`}
							>
								Your browser does not support the <code>audio</code> element.
							</audio>
							}

							{stand.type === "video" &&
							<video
								style={{width: "15vw"}}
								controls
								src={`${config.storage.baseUrl}${stand.resFile.find(resData=>resData.langCode === "fr").file}`}
							>
								Your browser does not support the
								<code>video</code> element.
							</video>
							}
						</p>
					</Grid>
				</Hidden>
				<Grid item xs={8} md={2} className={classes.rowContainer} style={{textAlign: "center"}}>
					<IconButton
						onClick={downloadQrCode}
						aria-label="edit"
						style={{
							backgroundColor: theme.palette.primary.main,
							color: "white",
							marginLeft: "1rem"
						}}
						className="ml-4"
					>
						<Tooltip title="Télécharger le QR Code">
							<GetAppRounded/>
						</Tooltip>
					</IconButton>
					<IconButton
						onClick={()=>{console.log("stand", stand);setModalIsOpen(true)}}
						aria-label="edit"
						style={{
							backgroundColor: theme.palette.secondary.main,
							color: "white",
							marginLeft: "1rem"
						}}
						className="ml-4"
					>
						<Tooltip title="Editer">
							<CreateRounded/>
						</Tooltip>
					</IconButton>
					<IconButton
						onClick={handleDelete}
						aria-label="delete"
						style={{
							backgroundColor: theme.palette.danger.main,
							color: "white",
							marginLeft: "1rem"
						}}
						className="ml-4"
					>
						<Tooltip title="Supprimer">
							<DeleteRounded />
						</Tooltip>
					</IconButton>
				</Grid>
			</Grid>
			<DialogStand
				handleClose={()=>{setModalIsOpen(false)}}
				isEdit={true}
				isOpen={modalIsOpen}
				stand={stand}
			/>
		</Grid>
	)
}