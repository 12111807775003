import React, {useContext, useEffect, useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {Button, Grid, Hidden, InputAdornment, TextField} from "@material-ui/core";
import {MDBAnimation, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";

import firebaseConnector from "../api/firebase-connector"

import ToastContext from "../context/toast.context";
import ActionContext from '../context/action.context'

import Alert from "../utils/Alert";
import StandRow from "../components/Stands/StandRow";
import DialogStand from "../components/Stands/DialogStand";
import StringUtils from "../utils/StringUtils";
import Swal from "sweetalert2";
import NotifRow from "../components/Notifications/NotifRow";
import {KeyboardArrowDownRounded, KeyboardArrowUpRounded} from "@material-ui/icons";
import DialogNotif from "../components/Notifications/DialogNotif";

export default function DashboardNotification() {
	const [notificationsList, setNotificationList] = useState([])
	const [notificationsListDisplayed, setNotificationListDisplayed] = useState([])
	const [orderBy, setOrderBy] = useState("originalDisplayTime")
	const [alphabeticalOrder, setAlphabeticalOrder] = useState(false)

	const [modalIsOpen, setModalIsOpen] = useState(false)

	const {toast} = useContext(ToastContext)

	const useStyles = makeStyles((theme) => ({

		inputSearch: {
			backgroundColor: "white",
		},
		table: {
			color: theme.palette.text.main,
			marginTop : "30px",
			borderRadius : "25px",
			[theme.breakpoints.down('sm')]: {
				borderRadius : "25px 25px 0px 0px",
			},
			backgroundColor : "white",
			boxShadow : "1px 13px 35px 0px rgba(0,0,0,0.15)",
			padding : "0px 15px 0px 15px"
		},
		rowText : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px"
		},
		rowTextCenter : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			textAlign : "center"
		},
		rowContainer : {
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			padding : "0px 5px"
		},
		buttonRowContainer : {
			padding : "0px 15px 0px 15px"
		},
		buttonRow : {
			display : "flex",
			alignItems : "center",
			justifyContent : "flex-end",
			[theme.breakpoints.down('sm')]: {
				justifyContent : "center",
			},
		}
	}));
	const classes = useStyles();

	useEffect(()=>{
		firebaseConnector.firestore().collection("Notifications").onSnapshot((snapshot)=>{
			setNotificationList(snapshot.docs.map((doc)=> {
				let newNotif = doc.data();
				try{
					if(newNotif.displayTime.seconds <= 883612800){
						newNotif.displayed = true;
					}else{
						newNotif.displayed = false;
					}
				}catch (e) {
					newNotif.displayed = false;
				}
				return newNotif;
			}))
		})
	}, [])

	useEffect(()=>{
		//Refresh affichage
	}, [notificationsListDisplayed])

	useEffect(()=>{
		const notifsFiltered = [...notificationsList];
		notifsFiltered.sort((a, b) => {
			if(orderBy === "originalDisplayTime"){
				//Filtre par date
				if(alphabeticalOrder){
					return (a[orderBy].seconds - b[orderBy].seconds);
				}else{
					return -(a[orderBy].seconds - b[orderBy].seconds);
				}
			}else if(orderBy.indexOf(".") > -1){
				let arrayOrderBy = orderBy.split(".");
				let objectA = a;
				let objectB = b;
				for(var prop of arrayOrderBy){
					objectA = objectA[prop]
					objectB = objectB[prop]
				}
				if(alphabeticalOrder){
					return -objectA.localeCompare(objectB);
				}else{
					return objectA.localeCompare(objectB);
				}
			}else{
				//Filtre par lettre
				if(alphabeticalOrder){
					return -a[orderBy].localeCompare(b[orderBy]);
				}else{
					return a[orderBy].localeCompare(b[orderBy]);
				}
			}
		});
		setNotificationListDisplayed(notifsFiltered)
	}, [notificationsList, alphabeticalOrder, orderBy])

	const handleHeaderClick = (value) => {
		if (value === orderBy) {
			setAlphabeticalOrder(!alphabeticalOrder)
		}else{
			setAlphabeticalOrder(false);
			setOrderBy(value)
		}
	}

	const editNotif = (notifChanged) => {
		console.log(notifChanged);
		if(notifChanged.displayed){
			notifChanged.displayTime = new Date("1998-01-01");
		}
		firebaseConnector.firestore().collection("Notifications").doc(notifChanged.id).update(notifChanged)
			.then(()=>{
				toast.fire({
					icon: "success",
					title: "Modification sauvegardée"
				})
			})
			.catch(Alert)

		//setNotificationList(notificationsList.map((notif) => notif.id === notifChanged.id ? notifChanged : notif))
	}
	const deleteNotif = (notifToDelete) => {
		firebaseConnector.firestore().collection("Stands").doc(notifToDelete.id).delete()
			.then(()=>{
				toast.fire({
					icon: "success",
					title: `Notification ${notifToDelete.name} supprimée`
				})
			})
			.catch(Alert)
	}
	const addNotif = (notifToAdd) => {
		notifToAdd = {...notifToAdd, ...{id: `Notification-${StringUtils.generateUuid()}`}};
		firebaseConnector.firestore()
			.collection("Notifications")
			.doc(notifToAdd.id)
			.set(notifToAdd)
			.then(()=>{
				toast.fire({
					icon: "success",
					title: `Notification ${notifToAdd.name} ajoutée`
				})
				setModalIsOpen(false)
			})
			.catch(Alert)
	}

	return (
		<ActionContext.Provider value={{addNotif, editNotif, deleteNotif}}>
			<Grid container>
				<Grid item xs={12}>
					<Grid container className={classes.buttonRowContainer}>
						<Grid xs={12} className={classes.buttonRow}>
							<p style={{margin : "0px"}}>
								<Button
									color="primary"
									variant="contained"
									size="large"
									onClick={()=>{setModalIsOpen(true)}}
									style={{borderRadius : "25px"}}
								>
									Prévoir une notification
								</Button>
							</p>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container  className={classes.table}>
						<Grid item xs={12} style={{fontWeight : "bold"}}>
							<Grid container style={{background : "transparent", borderBottom : "#e5ddcc 2px solid", padding : "15px"}}>
								<Grid item xs={5} md={2} className={classes.rowContainer}>
									<p className={classes.rowText}>
										<a onClick={() => handleHeaderClick("name")}>
											Nom {orderBy === "name" ? !alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}
										</a>
									</p>
								</Grid>
								<Hidden smDown>
									<Grid item xs={0} md={2} className={classes.rowContainer}>
										<p className={classes.rowText}>
											<a onClick={() => handleHeaderClick("originalDisplayTime")}>
												Date {orderBy === "originalDisplayTime" ? !alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}
											</a>
										</p>
									</Grid>
									<Grid item xs={0} md={2} className={classes.rowContainer}>
										<p className={classes.rowText}>
											<a onClick={() => handleHeaderClick("texts.fr.title")}>
												Titre {orderBy === "texts.fr.title" ? !alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}
											</a>
										</p>
									</Grid>
									<Grid item xs={0} md={3} className={classes.rowContainer}>
										<p className={classes.rowText}>
											<a onClick={() => handleHeaderClick("body")}>
												Contenu {orderBy === "texts.fr.body" ? !alphabeticalOrder ? (<KeyboardArrowDownRounded/>) : (<KeyboardArrowUpRounded/>) : <KeyboardArrowUpRounded style={{color : "white"}}/>}
											</a>
										</p>
									</Grid>
								</Hidden>
								<Grid item xs={2} md={1} className={classes.rowContainer}>
									<p className={classes.rowTextCenter}>
										Statut
									</p>
								</Grid>
								<Grid item xs={5} md={2} className={classes.rowContainer}>
									<p className={classes.rowTextCenter}>
										Actions
									</p>
								</Grid>
							</Grid>
						</Grid>
						{
							notificationsListDisplayed.length > 0 ? (
								notificationsListDisplayed.map((notif, index) => (
									<NotifRow
										key={index}
										notif={notif}
									/>
								))
							) : (
								<Grid item xs={12}>
									<p
										style={{
											width : "100%",
											textAlign : "center",
											padding : "20px 0px"
										}}
									>
										Pas de notification 😕
									</p>
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</Grid>
			<DialogNotif
				handleClose={()=>{setModalIsOpen(false)}}
				isEdit={false}
				isOpen={modalIsOpen}
				notif={null}
				editable={true}
			/>
		</ActionContext.Provider>
	);
}