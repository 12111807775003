import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import ToastContext from "./context/toast.context";
import Swal from 'sweetalert2';

import '@fortawesome/fontawesome-pro/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css'

import './index.css';

import App from './App';
import firebaseConnector from "./api/firebase-connector";
import ConfigContext from "./context/config.context";

import loginRightPanelImage from './assets/img/loginRightPanelImage.png'
import loginLeftPanelImage from './assets/img/loginLeftPanelImage.png'
import logoImage from './assets/img/logo.png'

const theme = createMuiTheme({
    palette: {
        primary: {
            contrastText: "#FFF",
            main: "#ba9f70",
            light: "#F8F5F0",
            background : "rgb(249, 246, 241)",
            backgroundGradient : "rgb(241, 234, 223)"
        },
        text: {
            main: "#0F3F62",
            light: "#79869F"
        },
        secondary: {
            main: "#5F37D1",
        },
        danger: {
            main: "#D13737"
        },
    },
})
const toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toastLocal) => {
        toastLocal.addEventListener('mouseenter', Swal.stopTimer)
        toastLocal.addEventListener('mouseleave', Swal.resumeTimer)
    }
})


let config = {
    storage: {
        baseUrl: ""
    },
    qr: {
        baseUrl: ""
    },
    title: "NFC Castle",
    loginLeftPanelImagePath: "loginLeftPanelImage.png",
    loginRightPanelImagePath: "loginRightPanelImage.png",
    logoImagePath: "logo.png",
    i18: [{code : "fr", text : "Français"}]
}

firebaseConnector
    .firestore()
    .collection("Configs")
    .doc("global-config")
    .get()
    .then(async (doc)=>{
        const {title, availablesLang, loginLeftPanelImagePath, loginRightPanelImagePath, logoImagePath} = doc.data();
        config.i18 = availablesLang;

        config = {...config, ...{
                title: title,
                loginLeftPanelImagePath: loginLeftPanelImagePath,
                loginRightPanelImagePath: loginRightPanelImagePath,
                logoImagePath: logoImagePath
            }}
        document.title = config.title
    })

firebaseConnector
    .firestore()
    .collection("Configs")
    .doc("storage-config")
    .get()
    .then((doc)=>{
        const {baseUrl, testBaseUrl} = doc.data();
        config.storage.baseUrl = process.env.NODE_ENV !== "production" ? testBaseUrl : baseUrl;
    })

firebaseConnector
    .firestore()
    .collection("Configs")
    .doc("qr-config")
    .get()
    .then((doc)=>{
        const {baseUrl} = doc.data();
        config.qr.baseUrl = baseUrl;
    })

ReactDOM.render(
    <BrowserRouter>
        <ThemeProvider theme={theme}>
            <ConfigContext.Provider value={{config: config}}>
                <ToastContext.Provider value={{toast: toast}}>
                    <App/>
                </ToastContext.Provider>
            </ConfigContext.Provider>
        </ThemeProvider>
    </BrowserRouter>,
    document.getElementById('root')
);