import React, {useContext, useEffect, useState} from 'react'

import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import {ButtonGroup, Grid, TextField} from "@material-ui/core";
import {ImageRounded, TitleRounded} from "@material-ui/icons";
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import ConfigContext from "../../../context/config.context";

export default function SimpleMarkdownViewer({value, imageFiles}){

    const [valueMarkdown, setValueMarkdown] = useState(value ? value : "");
    const {config} = useContext(ConfigContext)

    const useStyles = makeStyles((theme) => ({
        root : {
            height : "100%",
            width : "100%",
            padding : "15px",
            overflow : "auto"
        },
        markdownImageContainer : {
            width : "100%",
            height : "150px",
            padding : "0px",
            backgroundPosition : "center",
            backgroundSize : "contain",
            backgroundRepeat : "no-repeat"
        },
        markdownImage : {
            maxWidth : "50%",
            height : "100%"
        },
        markdownText : {
            color : "#797687"
        },
        mardownTitle : {
            color : theme.palette.text.main,
            fontWeight : "500",
            width : "100%",
            textAlign : "center"
        }
    }));
    const classes = useStyles();

    useEffect(() => {
        setValueMarkdown(value)
    }, [value])

    return(
        <div className={classes.root}>
            {
                valueMarkdown !== null && valueMarkdown !== undefined && valueMarkdown.split("\n").map((ligne, index) => {
                    if(ligne.slice(0,2) === "# "){
                        return (
                            <h4 key={index} className={classes.mardownTitle}>
                                {ligne.slice(2)}
                            </h4>
                        )
                    }else if(ligne.slice(0,2) === "{{" && ligne.slice(-2) === "}}"){
                        var numImage = parseInt(ligne.slice(2).slice(0, -2).split(":")[1])
                        if(imageFiles.length >= numImage){
                            return (
                                <div className={classes.markdownImageContainer} style={{backgroundImage : "url(" + imageFiles[numImage] + ")"}}/>
                            )
                        }else{
                            return(<></>)
                        }

                    }else if(ligne.slice(0,2) === "[[" && ligne.slice(-2) === "]]"){
                        var url = ligne.slice(2).slice(0, -2)
                        if(url.slice(0,4) !== "http"){
                            //Construction de l'url
                            url = config.storage.baseUrl + url
                        }
                        return (
                            <div className={classes.markdownImageContainer} style={{backgroundImage : "url(" + url + ")"}}/>
                        )

                    }else{
                        return (
                            <p className={classes.markdownText}>
                                {ligne}
                            </p>
                        )
                    }
                })
            }
        </div>
    )
}
