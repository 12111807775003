import React from "react";
import { useHistory, useLocation } from 'react-router-dom'
import {makeStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";

export default function SideBarLink({to, children}){
    let history = useHistory();
    let location = useLocation();

    const useStyles = makeStyles((theme) => ({
        drawerItem: {
            marginBottom: "1rem",
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            fontWeight: 300,
            color: theme.palette.text.light
        },
        drawerItemActive: {
            marginBottom: "1rem",
            paddingLeft: "2rem",
            color: theme.palette.primary.main,
            backgroundColor: "rgb(186,159,112, 0.15)!important",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            fontWeight: 300,
            "& > .MuiListItemIcon-root > i": {
                color: theme.palette.primary.main,
            }
        },
    }))
    const classes = useStyles();

    return(
        <ListItem
            button
            onClick={()=>{history.push(to)}}
            alignItems={'center'}
            selected={location.pathname === to}
            className={location.pathname === to ? classes.drawerItemActive : classes.drawerItem}
        >
            {children}
        </ListItem>
    )
}