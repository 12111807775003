import React, {useContext, useEffect, useState} from "react";
import {
	Button,
	Dialog, DialogActions,
	DialogContent,
	DialogTitle,
	FormControl, Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@material-ui/core";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import moment from "moment";
import MomentUtils from '@date-io/moment';
import "moment/locale/fr";

import {MDBCol, MDBRow} from "mdbreact";
import ConfigContext from "../../context/config.context";
import axios from "axios";

import ActionContext from "../../context/action.context";
import FileInput from "../Stands/FileInput";
import {makeStyles} from "@material-ui/core/styles";
import iphoneOverlay from "../../assets/img/iphone-overlay.png";
import iphoneBlack from "../../assets/img/iphone-black.png";
import iphoneBackground from "../../assets/img/iphone-background.png";
import logoApp from "../../assets/img/appIcon.png"
import {ScheduleRounded, TouchAppRounded} from "@material-ui/icons";

moment.locale("fr");

export default function DialogNotif({notif: notifProp, isEdit, isOpen, handleClose, editable}){
	const [notif, setNotif] = useState(isEdit ? notifProp : {
		name: "Ma notification",
		type: "none",
		option: "",
		originalDisplayTime : new Date(Date.now()),
		displayTime : new Date(Date.now()),
		id : "",
		texts : {

		}
	})
	const [isValid, setIsValid] = useState(false)
	const [isTextsValid, setIsTextsValid] = useState(true)
	const [actualEditingLanguage, setActualEditingLanguage] = useState("fr")
	const [timeTemp, setTimeTemp] = useState("12:00")

	const {config} = useContext(ConfigContext)
	const {addNotif, editNotif} = useContext(ActionContext)

	const useStyles = makeStyles((theme) => ({
		gradientBg : {
			background : "linear-gradient(to right, " + theme.palette.primary.background + ", " + theme.palette.primary.background + " 55%, " + theme.palette.primary.backgroundGradient + " 80%)",
			overflow : "auto",
		},
		phonePart : {
			maxHeight : "75vh",
			minHeight : "600px",
			display : "flex",
			justifyContent : "center",
			alignItems : "center",
			[theme.breakpoints.down('md')]: {
				width : "100%"
			},
		},
		phone : {
			backgroundImage : "url(" + iphoneOverlay + "), url(" + iphoneBlack + "), url(" + iphoneBackground + ")",
			backgroundSize : "contain",
			backgroundRepeat : "no-repeat",
			backgroundPosition : "center",
			width : "260px",
			minWidth : "260px",
			height : "533px"
		},
		phoneHour : {
			textAlign : "center",
			color : "white",
			paddingTop : "50px",
			margin : "0px",
			fontSize : "57px",
			fontWeight : "350"
		},
		phoneDate : {
			textAlign : "center",
			color : "white",
			marginTop : "-10px",
			fontSize : "12px",
			fontWeight : "500"
		},
		phoneNotificationContainer : {
			display : "flex",
			justifyContent : "center",
			width : "100%"
		},
		phoneNotificationBlur : {
			backdropFilter: "blur(10px)",
			width : "215px",
			minHeight : "60px",
		},
		phoneNotification : {
			backgroundColor : "rgba(255, 255, 255, 0.5)",
			width : "100%",
			height : "100%",
			borderRadius : "8px"
		},
		phoneNotificationAppIcon : {
			width : "14px",
			height : "14px",
			borderRadius : "3px",
			backgroundImage : "url(" + logoApp + ")",
			backgroundSize : "contain",
			backgroundRepeat : "no-repeat",
			backgroundPosition : "center",
			margin : "6px 0px 0px 5px"
		},
		phoneNotificationAppName : {
			fontSize : "9px",
			fontWeight : "400",
			paddingLeft : "5px",
			paddingTop : "7px"
		},
		phoneNotificationTime : {
			fontSize : "9px",
			textAlign : "right",
			fontWeight : "400",
			paddingRight : "8.5px",
			paddingTop : "7px"
		},
		phoneNotificationTitle : {
			fontSize : "9px",
			fontWeight : "800",
			letterSpacing : "0.35px",
			paddingLeft : "8.5px",
			paddingTop : "7px",
			margin : "0px"
		},
		phoneNotificationBody : {
			fontSize : "9px",
			fontWeight : "500",
			paddingLeft : "8.5px",
			wordBreak : "break-word"
		}
	}));
	const classes = useStyles();

	const formatterHour = new Intl.DateTimeFormat('fr', { hour : "2-digit", minute : "2-digit"});
	const formatterDate = new Intl.DateTimeFormat('fr', { weekday : "long", day : "2-digit", month: 'long' });

	const capitalize = (s) => {
		if (typeof s !== 'string') return ''
		return s.charAt(0).toUpperCase() + s.slice(1)
	}

	useEffect(()=>{
		setNotif(notifProp)
	}, [notifProp])

	useEffect(()=>{
		if(!(notif.originalDisplayTime instanceof Date)){

			var strHour = notif.originalDisplayTime.toDate().getHours();
			strHour = (parseInt(strHour) < 0 ? "0" : (parseInt(strHour) > 23 ? "23" : strHour))
			strHour = (parseInt(strHour) < 10 ? "0" + parseInt(strHour) : "" + parseInt(strHour))

			var strMinutes = notif.originalDisplayTime.toDate().getMinutes()
			strMinutes = (parseInt(strMinutes) < 0 ? "0" : (parseInt(strMinutes) > 55 ? "55" : strMinutes))
			strMinutes = (parseInt(strMinutes) % 5 !== 0 ? "" + (parseInt(strMinutes) - parseInt(strMinutes) % 5) : strMinutes)
			strMinutes = (parseInt(strMinutes) < 10 ? "0" + parseInt(strMinutes) : "" + parseInt(strMinutes))

			setTimeTemp(strHour + ":" + strMinutes)
			setNotif({
				...notif,
				...{originalDisplayTime: notif.originalDisplayTime.toDate()},
				...{displayTime: notif.originalDisplayTime.toDate()}
			})
		}

		let valid = notif.name !== "" && notif.type !== "" && notif.originalDisplayTime !== null && notif.displayTime !== null;

		setIsValid(valid)

		if(notif.originalDisplayTime instanceof Date){

			var strHour = notif.originalDisplayTime.getHours();
			strHour = (parseInt(strHour) < 0 ? "0" : (parseInt(strHour) > 23 ? "23" : strHour))
			strHour = (parseInt(strHour) < 10 ? "0" + parseInt(strHour) : "" + parseInt(strHour))

			var strMinutes = notif.originalDisplayTime.getMinutes()
			strMinutes = (parseInt(strMinutes) < 0 ? "0" : (parseInt(strMinutes) > 55 ? "55" : strMinutes))
			strMinutes = (parseInt(strMinutes) % 5 !== 0 ? "" + (parseInt(strMinutes) - parseInt(strMinutes) % 5) : strMinutes)
			strMinutes = (parseInt(strMinutes) < 10 ? "0" + parseInt(strMinutes) : "" + parseInt(strMinutes))

			setTimeTemp(strHour + ":" + strMinutes)
			if(notif.originalDisplayTime.getMinutes() % 5 !== 0){
				handleBlurTime(strHour + ":" + strMinutes)
			}
		}

	}, [notif])

	useEffect(()=>{
		if(!isEdit){
			var texts = {};
			for(var language of config.i18){
				texts = {
					...texts,
					[language.code] : {
						title : "Titre en " + language.text.toLowerCase(),
						body : "Corps en " + language.text.toLowerCase(),
						languageCode : language.code,
						languageText : language.text
					}
				}
			}

			setNotif({...notif, ...{texts: texts}})
		}
	}, [isEdit, isOpen, config.i18])

	useEffect(() => {

	}, [editable])

	const handleChange = (prop) => (event) => {
		setNotif({...notif, ...{[prop]: event.target.value}})
	}

	const handleChangeDate = (value) => {

		try{
			value = value.toObject()
		}catch (e) {
			value = moment().toObject()
		}

		if(!value.years || !value.months || !value.date){
			value = moment().toObject()
		}

		var strYear = "" + value.years
		var strMonth = (value.months < 9 ? ("0" + (value.months + 1)) : "" + (value.months + 1))
		var strDay = (value.date < 9 ? ("0" + value.date) : ("" + value.date))
		var strHour = (notif.originalDisplayTime.getHours() < 9 ? ("0" + notif.originalDisplayTime.getHours()) : ("" + notif.originalDisplayTime.getHours()))
		var strMinutes = (notif.originalDisplayTime.getMinutes() < 9 ? ("0" + notif.originalDisplayTime.getMinutes()) : ("" + notif.originalDisplayTime.getMinutes()))

		var strDate = strYear + "-" + strMonth + "-" + strDay  + "T" + strHour + ":" + strMinutes;

		setNotif({
			...notif,
			...{originalDisplayTime: new Date(strDate)},
			...{displayTime: new Date(strDate)}
		})
	}

	const handleBlurTime = (value) => {

		var strYear = "" + notif.originalDisplayTime.getFullYear()
		var strMonth = notif.originalDisplayTime.getMonth() + 1;
		strMonth = (strMonth < 10 ? "0" + strMonth : "" + strMonth)
		var strDay = notif.originalDisplayTime.getDate();
		strDay = (strDay < 10 ? "0" + strDay : "" + strDay)
		var strHour = value.split(":")[0] ? value.split(":")[0] : "00";
		strHour = (parseInt(strHour) < 0 ? "0" : (parseInt(strHour) > 23 ? "23" : strHour))
		strHour = (parseInt(strHour) < 10 ? "0" + parseInt(strHour) : "" + parseInt(strHour))
		var strMinutes = value.split(":")[1] ? value.split(":")[1] : "00";
		strMinutes = (parseInt(strMinutes) < 0 ? "0" : (parseInt(strMinutes) > 55 ? "55" : strMinutes))
		strMinutes = (parseInt(strMinutes) % 5 !== 0 ? "" + (parseInt(strMinutes) - parseInt(strMinutes) % 5) : strMinutes)
		strMinutes = (parseInt(strMinutes) < 10 ? "0" + parseInt(strMinutes) : "" + parseInt(strMinutes))

		var strDate = strYear + "-" + strMonth + "-" + strDay  + "T" + strHour + ":" + strMinutes;

		setNotif({
			...notif,
			...{originalDisplayTime: new Date(strDate)},
			...{displayTime: new Date(strDate)}
		})
	}

	const handleChangeTime = (value) => {
		setTimeTemp(value)
	}

	const handleChangeTexts = (languageCode, prop, value) => {

		if((prop === "body" && value.length > 180) || (prop === "title" && value.length > 40)){
			return
		}

		let isTextsValid = true
		if(value === ""){
			isTextsValid = false
		}else{
			for(let key of Object.keys(notif.texts)){
				if(notif.texts[key].title === "" || notif.texts[key].body === ""){
					isTextsValid = false
				}
			}
		}
		setIsTextsValid(isTextsValid);

		var newNotif = notif;
		newNotif.texts[languageCode][prop] = value;

		setNotif({...newNotif})
	}

	const handleFocusTexts = (languageCode) => {
		setActualEditingLanguage(languageCode)
	}

	const handleSubmit = async () => {
		if(isEdit){
			editNotif(notif)
		}else{
			addNotif(notif)
		}
		handleClose();
	}

	return(
		<Dialog
			fullWidth={true}
			maxWidth={"md"}
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title" className={classes.gradientBg}>
				{isEdit ? "Modifier" : "Ajouter"} une notification
			</DialogTitle>
			<DialogContent className={classes.gradientBg}>
				<Grid container style={{height : "100%"}}>
					<Grid item xs={12} md={7} lg={7}>
						<TextField
							onChange={handleChange("name")}
							value={notif.name}
							required
							margin="dense"
							label="Nom de la notification"
							type="text"
							style={{width : "80%"}}
						/>
						<hr/>
						<Grid container>
							{
								Object.keys(notif.texts).map((key, index) => (
									<Grid item xs={12} key={index} style={{marginBottom : "15px"}}>
										<Grid container>
											<Grid item xs={12} md={1}>
												<p style={{fontWeight : "bold", margin : "15px 0px 15px 0px", textAlign : "center"}}>{key.toUpperCase()}</p>
											</Grid>
											<Grid item xs={12} md={5}>
												<TextField
													disabled={!editable}
													onChange={(event) => {handleChangeTexts(key, "title", event.target.value)}}
													onFocus={() => handleFocusTexts(key)}
													value={notif.texts[key].title}
													required
													margin="dense"
													label={"Titre en " + String(notif.texts[key].languageText).toLowerCase()}
													type="text"
													style={{width : "80%", marginLeft : "10%"}}
												/>
											</Grid>
											<Grid item xs={12} md={6}>
												<TextField
													disabled={!editable}
													onChange={(event) => {handleChangeTexts(key, "body", event.target.value)}}
													onFocus={() => handleFocusTexts(key)}
													value={notif.texts[key].body}
													required
													margin="dense"
													label={"Corps en " + String(notif.texts[key].languageText).toLowerCase()}
													type="text"
													style={{width : "80%", marginLeft : "10%"}}
												/>
											</Grid>
										</Grid>
									</Grid>
								))
							}
						</Grid>
						<hr/>
						<Grid container>
							<Grid item xs={1} style={{textAlign : "center", padding : "15px 0px 15px 0px"}}>
								<ScheduleRounded/>
							</Grid>
							<Grid item xs={5}>
								<MuiPickersUtilsProvider utils={MomentUtils}>
									<KeyboardDatePicker
										disabled={!editable}
										disableToolbar
										variant="inline"
										format="DD/MM/YYYY"
										id="date-picker-inline"
										label="Date de diffusion"
										value={notif.originalDisplayTime instanceof Date ? notif.originalDisplayTime : notif.originalDisplayTime.toDate()}
										onChange={handleChangeDate}
										KeyboardButtonProps={{
											'aria-label': 'change date',
										}}
										style={{width : "80%", marginLeft : "10%"}}
									/>
								</MuiPickersUtilsProvider>
							</Grid>
							<Grid item xs={6}>
								<TextField
									disabled={!editable}
									onBlur={(event) => handleBlurTime(event.target.value)}
									onChange={(event) => handleChangeTime(event.target.value)}
									id="time"
									label="Heure de diffusion"
									type="time"
									value={timeTemp}
									style={{width : "80%", marginLeft : "10%"}}
								/>
							</Grid>
							{(editable && (notif.originalDisplayTime instanceof Date && notif.originalDisplayTime < new Date(Date.now()))) && (
								<>
									<Grid item xs={1}/>
									<Grid item xs={11} style={{display : "flex", justifyContent : "center"}}>
										<p style={{textAlign : "center", color : "red", fontSize : "80%", margin : "10px 0px 5px 0px"}}>
											La notification sera envoyé tout de suite. Vous ne pourrez pas la modifier.
										</p>
									</Grid>
								</>
							)}
						</Grid>
						<hr/>
						<Grid container>
							<Grid item xs={1} style={{textAlign : "center", padding : "15px 0px 15px 0px"}}>
								<TouchAppRounded/>
							</Grid>
							<Grid item xs={5}>
								<InputLabel
									shrink id="demo-simple-select-placeholder-label-label"
									style={{width : "80%", marginLeft : "10%", marginBottom : "0px"}}
								>
									Action
								</InputLabel>
								<Select
									disabled={!editable}
									labelId="file-type-select-label"
									id="file-type-select"
									value={notif.type}
									onChange={handleChange("type")}
									style={{width : "80%", marginLeft : "10%"}}
								>
									<MenuItem value="none">Ouvrir l'application</MenuItem>
									<MenuItem value="url">Ouvrir une URL</MenuItem>
								</Select>
							</Grid>
							<Grid item xs={6}>
								{
									notif.type === "url" && (
										<TextField
											disabled={!editable}
											onChange={handleChange("option")}
											id="time"
											label="URL"
											type="text"
											value={notif.option}
											style={{width : "80%", marginLeft : "10%"}}
										/>
									)
								}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={0} md={1} lg={1}/>
					<Grid item xs={0} md={4} lg={4}  className={classes.phonePart}>
						<Grid container>
							<Grid item xs={12} md={12} className={classes.phone}>
								<p className={classes.phoneHour}>
									{
										notif.originalDisplayTime instanceof Date ?
											formatterHour.format(notif.originalDisplayTime) :
											"00:00"
									}
								</p>
								<p className={classes.phoneDate}>
									{
										notif.originalDisplayTime instanceof Date ?
											capitalize(formatterDate.format(notif.originalDisplayTime)) :
											"10 avril 2014"
									}
								</p>
								<div className={classes.phoneNotificationContainer}>
									<div className={classes.phoneNotificationBlur}>
										<div className={classes.phoneNotification}>
											<Grid container style={{width : "100%"}}>
												<Grid item xs={12} style={{height : "18px"}}>
													<Grid container style={{width : "100%"}}>
														<Grid item xs={1}>
															<div className={classes.phoneNotificationAppIcon}/>
														</Grid>
														<Grid item xs={7}>
															<p className={classes.phoneNotificationAppName}>
																CHATEAU DE LOCHES
															</p>
														</Grid>
														<Grid item xs={4}>
															<p className={classes.phoneNotificationTime}>
																Maintenant
															</p>
														</Grid>
													</Grid>
												</Grid>
												<Grid item xs={12}>
													<p className={classes.phoneNotificationTitle}>
														{notif.texts[actualEditingLanguage] ? notif.texts[actualEditingLanguage].title : "Titre"}
													</p>
													<p className={classes.phoneNotificationBody}>
														{notif.texts[actualEditingLanguage] ? notif.texts[actualEditingLanguage].body : "Corps"}
													</p>
												</Grid>
											</Grid>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className={classes.gradientBg}>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid || !isTextsValid}>
					{isEdit ? "Modifier" : "Ajouter"}
				</Button>
			</DialogActions>
		</Dialog>
	)
}