import React, {useContext, useEffect, useState} from 'react'

import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import {Button, Grid, Hidden, InputAdornment, TextField} from "@material-ui/core";
import {MDBAnimation, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from "mdbreact";

import firebaseConnector from "../api/firebase-connector"

import ToastContext from "../context/toast.context";
import ActionContext from '../context/action.context'

import Alert from "../utils/Alert";
import StandRow from "../components/Stands/StandRow";
import DialogStand from "../components/Stands/DialogStand";
import StringUtils from "../utils/StringUtils";
import axios from "axios";
import ConfigContext from "../context/config.context";
import {KeyboardArrowDownRounded, KeyboardArrowUpRounded} from "@material-ui/icons";
import NotifRow from "../components/Notifications/NotifRow";
import DialogNotif from "../components/Notifications/DialogNotif";

export default function DashboardStand() {
	const [standsList, setStandsList] = useState([])
	const [standsListDisplay, setStandsListDisplay] = useState([])
	const [filter, setFilter] = useState("")

	const [modalIsOpen, setModalIsOpen] = useState(false)

	const {config} = useContext(ConfigContext)
	const {toast} = useContext(ToastContext)
	const useStyles = makeStyles((theme) => ({
		inputSearch: {
			backgroundColor: "white",
		},
		table: {
			color: theme.palette.text.main,
			marginTop : "30px",
			borderRadius : "25px",
			[theme.breakpoints.down('sm')]: {
				borderRadius : "25px 25px 0px 0px",
			},
			backgroundColor : "white",
			boxShadow : "1px 13px 35px 0px rgba(0,0,0,0.15)",
			padding : "0px 15px 0px 15px"
		},
		rowText : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px"
		},
		rowTextCenter : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			textAlign : "center"
		},
		rowContainer : {
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			padding : "0px 5px"
		},
		buttonRowContainer : {
			padding : "0px 15px 0px 15px"
		},
	}));

	const classes = useStyles();

	useEffect(()=>{
		firebaseConnector.firestore().collection("Stands").orderBy("name", "desc").onSnapshot((snapshot)=>{
			setStandsList(snapshot.docs.map(doc=>doc.data()))
		})
	}, [])
	useEffect(()=>{
		const standsFiltered= standsList.filter(stand=>stand.name.toLowerCase().includes(filter.toLowerCase()));
		setStandsListDisplay(standsFiltered)
	}, [standsList, filter])

	const editStand = (standChanged) => {
		firebaseConnector.firestore().collection("Stands").doc(standChanged.id).update(standChanged)
			.then(()=>{
				toast.fire({
					icon: "success",
					title: "Modification sauvegardée"
				})
			})
			.catch(Alert.error)

		setStandsList(standsList.map(stand => stand.id === standChanged.id ? standChanged : stand))
	}
	const deleteStand = (standToDelete) => {
		let promisesArray = [
			firebaseConnector.firestore().collection("Stands").doc(standToDelete.id).delete(),
			axios.delete(`${config.storage.baseUrl}/storage/${standToDelete.image.replace("/static/", "")}`)
		]
		/*promisesArray.push(async () => {
			if(standToDelete.type === "markdown"){

			}else{
				await
			}
		});*/
		if(standToDelete.type === "markdown"){
			standToDelete.resFile.map(async (resData) => {
				let arrayImage = resData.file
					.split("\n")
					.filter(ligne => (ligne.slice(0,2) === "[[" && ligne.slice(-2) === "]]"))
					.map(val => val.slice(2,-2))

				return Promise.all(arrayImage.map((file) => (axios.delete(`${config.storage.baseUrl}/storage/${file.replace("/static/", "")}`))));
			})
		}else{
			promisesArray.push(...standToDelete.resFile.map(resData => axios.delete(`${config.storage.baseUrl}/storage/${resData.file.replace("/static/", "")}`)))
		}
		console.log(promisesArray);
		Promise.all(promisesArray).then(()=>{
			toast.fire({
				icon: "success",
				title: `Stand ${standToDelete.name} supprimé`
			})
		}).catch(Alert.error)
	}
	const addStand = (standToAdd) => {
		standToAdd = {...standToAdd, ...{id: `Stand-${StringUtils.generateUuid()}`}};
		firebaseConnector.firestore()
			.collection("Stands")
			.doc(standToAdd.id)
			.set(standToAdd)
			.then(()=>{
				toast.fire({
					icon: "success",
					title: `Stand ${standToAdd.name} ajouté`
				})
				setModalIsOpen(false)
			})
			.catch(Alert.error)
	}

	return (
		<ActionContext.Provider value={{addStand, editStand, deleteStand}}>
			<Grid container>
				<Grid item xs={12}>
					<Grid container className={classes.buttonRowContainer}>
						<Grid item xs={6} md={4}>
							<TextField
								onChange={(event)=>{setFilter(event.target.value)}}
								variant="outlined"
								value={filter}
								className={classes.inputSearch}
								id="input-search"
								fullWidth={true}
								placeholder="Rechercher un stand par nom"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon />
										</InputAdornment>
									),
								}}
							/>
						</Grid>
						<Grid xs={1} md={4}/>
						<Grid xs={5} md={4} style={{display : "flex", alignItems : "center", justifyContent : "flex-end"}}>
							<p style={{margin : "0px"}}>
								<Button
									color="primary"
									variant="contained"
									size="large"
									onClick={()=>{setModalIsOpen(true)}}
									style={{borderRadius : "25px"}}
								>
									Ajouter un stand
								</Button>
							</p>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Grid container  className={classes.table}>
						<Grid item xs={12} style={{fontWeight : "bold"}}>
							<Grid container style={{background : "transparent", borderBottom : "#e5ddcc 2px solid", padding : "15px"}}>
								<Grid item xs={4} md={2} className={classes.rowContainer}>
									<p className={classes.rowText}>
										Nom
									</p>
								</Grid>
								<Hidden smDown>
									<Grid item xs={0} md={2} className={classes.rowContainer}>
										<p className={classes.rowText}>
											NFC ID
										</p>
									</Grid>
									<Grid item xs={0} md={2} className={classes.rowContainer}>
										<p className={classes.rowText}>
											Type
										</p>
									</Grid>
									<Grid item xs={0} md={4} className={classes.rowContainer}>
										<p className={classes.rowText}>
											Fichier
										</p>
									</Grid>
								</Hidden>
								<Grid item xs={8} md={2} className={classes.rowContainer}>
									<p className={classes.rowTextCenter}>
										Actions
									</p>
								</Grid>
							</Grid>
						</Grid>
						{
							standsListDisplay.length > 0 ? (
								standsListDisplay.map((stand, index) => (
									<StandRow
										key={index}
										stand={stand}
									/>
								))
							) : (
								<Grid item xs={12}>
									<p
										style={{
											width : "100%",
											textAlign : "center",
											padding : "20px 0px"
										}}
									>
										Pas de stands 😕
									</p>
								</Grid>
							)
						}
					</Grid>
				</Grid>
			</Grid>
			<DialogStand
				handleClose={()=>{setModalIsOpen(false)}}
				isEdit={false}
				isOpen={modalIsOpen}
				stand={null}
			/>
		</ActionContext.Provider>
	);
}