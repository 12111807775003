import React, {useContext, useEffect, useState} from "react";
import {Button, Grid, Hidden, IconButton, Tooltip} from "@material-ui/core";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import {makeStyles, useTheme} from "@material-ui/core/styles";

import firebaseConnector from "../../api/firebase-connector";

import Alert from "../../utils/Alert";

import ToastContext from "../../context/toast.context";
import DialogStand from "../Stands/DialogStand";
import ConfigContext from "../../context/config.context";
import {AwesomeQR} from "@awesomeqr/react";

import logo from '../../assets/img/logo.png'
import DialogNotif from "./DialogNotif";
import {
	CancelRounded,
	CheckCircleRounded, CreateRounded, DeleteRounded, KeyboardArrowDownRounded, KeyboardArrowUpRounded,
	Schedule,
	ScheduleRounded,
	Visibility,
	VisibilityRounded
} from "@material-ui/icons";
import iphoneOverlay from "../../assets/img/iphone-overlay.png";
import iphoneBlack from "../../assets/img/iphone-black.png";
import iphoneBackground from "../../assets/img/iphone-background.png";
import logoApp from "../../assets/img/appIcon.png";
const typeText = {
	sound: "son",
	text: "texte",
	video: "vidéo"
}

export default function NotifRow({notif}){
	const theme = useTheme()
	const {toast} = useContext(ToastContext)
	const {config} = useContext(ConfigContext)

	const [modalIsOpen, setModalIsOpen] = useState(false)

	const useStyles = makeStyles((theme) => ({
		iconSend : {
			color : "green",
			fontSize : "250%"
		},
		iconWait : {
			color : theme.palette.primary.main,
			fontSize : "250%"
		},
		rowText : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			wordBreak : "break-word"
		},
		rowTextCenter : {
			textOverflow : "ellipsis",
			width : "100%",
			overflow : "hidden",
			margin : "0px",
			textAlign : "center",
			wordBreak : "break-word"
		},
		rowContainer : {
			display : "flex",
			alignItems : "center",
			justifyContent : "center",
			padding : "0px 5px"
		}
	}));
	const classes = useStyles();

	useEffect(() => {

	}, [notif])

	const handleDelete = () => {
		Alert.warningDelete().then(()=>{

			console.log("Delete " + notif.id)

			firebaseConnector
				.firestore()
				.collection("Notifications")
				.doc(notif.id)
				.delete()
				.then(()=>{
					toast.fire({icon: "success", title: "Notification supprimé"})
				})
				.catch(Alert.error)
		})
	}

	const formatFirebaseTimestamp = (timestamp) => {
		let dateObj = timestamp.toDate();

		let jour = dateObj.getDate();
		if(jour < 10){
			jour = "0" + jour;
		}

		let mois = dateObj.getMonth() + 1;
		if(mois < 10){
			mois = "0" + mois;
		}

		let hours = dateObj.getHours();
		if(hours < 10){
			hours = "0" + hours;
		}

		let minutes = dateObj.getMinutes();
		if(minutes < 10){
			minutes = "0" + minutes;
		}

		return jour + "/" + mois + "/" + dateObj.getFullYear() + " " + hours + ":" + minutes;
	}


	return(
		<Grid item xs={12} style={{background : "transparent", borderBottom : "#e5ddcc 1px solid"}}>
			<Grid container style={{padding : "15px"}}>
				<Grid item xs={5} md={2} className={classes.rowContainer}>
					<p className={classes.rowText}>
						{notif.name}
					</p>
				</Grid>
				<Hidden smDown>
					<Grid item xs={0} md={2} className={classes.rowContainer}>
						<p className={classes.rowText}>
							{formatFirebaseTimestamp(notif.originalDisplayTime)}
						</p>
					</Grid>
					<Grid item xs={0} md={2} className={classes.rowContainer}>
						<p className={classes.rowText}>
							{notif.texts.fr.title}
						</p>
					</Grid>
					<Grid item xs={0} md={3} className={classes.rowContainer}>
						<p className={classes.rowText}>
							{notif.texts.fr.body}
						</p>
					</Grid>
				</Hidden>
				<Grid item xs={2} md={1} className={classes.rowContainer}>
					<p className={classes.rowTextCenter}>
						{(notif.displayTime.seconds <= 883612800) ?
							(
								<Tooltip title={"La notification a été distribuée le " + formatFirebaseTimestamp(notif.originalDisplayTime).replace(" ", " à ")}>
									<CheckCircleRounded className={classes.iconSend}/>
								</Tooltip>
							) :
							(
								<Tooltip title={"La distribution est prévue pour le " + formatFirebaseTimestamp(notif.originalDisplayTime).replace(" ", " à ")}>
									<ScheduleRounded className={classes.iconWait}/>
								</Tooltip>
							)
						}
					</p>
				</Grid>
				<Grid item xs={5} md={2} className={classes.rowContainer} style={{textAlign: "center"}}>
					<IconButton
						onClick={()=>{setModalIsOpen(true)}}
						aria-label="edit"
						style={{
							backgroundColor: theme.palette.secondary.main,
							color: "white",
							marginLeft: "1rem"
						}}
						className="ml-4"
					>
						{notif.displayTime.seconds > 883612800 ?
							(
								<Tooltip title="Editer">
									<CreateRounded/>
								</Tooltip>
							) :
							(
								<Tooltip title="Voir">
									<VisibilityRounded/>
								</Tooltip>
							)
						}
					</IconButton>
					<IconButton
						onClick={handleDelete}
						aria-label="delete"
						style={{
							backgroundColor: theme.palette.danger.main,
							color: "white",
							marginLeft: "1rem"
						}}
						className="ml-4"
					>
						<Tooltip title="Supprimer">
							<DeleteRounded />
						</Tooltip>
					</IconButton>
				</Grid>
			</Grid>
			<DialogNotif
				handleClose={()=>{setModalIsOpen(false)}}
				isEdit={true}
				isOpen={modalIsOpen}
				notif={notif}
				editable={!notif.displayed}
			/>
		</Grid>



	)
}