import React from 'react'

import ImageIcon from '@material-ui/icons/Image';
import Button from '@material-ui/core/Button';

export default function FileInput({text, accept, file, handleFileSelected, direction = "column"}){
	return(
		<div style={{display : "flex", flexDirection : direction, justifyContent : "center", alignItems : "center"}}>
			<Button
				color="primary"
				variant="contained"
				startIcon={<ImageIcon />}
				component="label"
				style={{textAlign : "center"}}
			>
				{text}
				<input
					onChange={(event => {handleFileSelected(event.target.files[0])})}
					accept={accept}
					type="file"
					hidden
				/>
			</Button>
			<p style={{textAlign : "center", margin : "0px 15px 0px 15px", textOverflow : "ellipsis", overflow : "hidden", width : "90%"}}>
				{(file !== null && file !== undefined) ? file.name : ""}
			</p>
		</div>
	)
}
