import React from "react";
import { Switch, Route, Redirect } from 'react-router-dom';

import DashboardStand from "../section/stand.dashboard";
import DashboardNotification from "../section/notification.dashboard";

const RoutesApp = () => {
    return (
        <Switch>
            <Route path='/dashboard' exact component={()=>(<Redirect to="/dashboard/stand"/>)}/>

            <Route path="/dashboard/stand" exact={true} component={DashboardStand}/>
            <Route path="/dashboard/notification" exact={true} component={DashboardNotification}/>

            <Route>
                <Redirect to="/dashboard/stand"/>
            </Route>
        </Switch>
    );
};

export default RoutesApp;