import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {MDBSpinner} from "mdbreact";

import RoutesWithNavigationApp from "./routes-navigation/RoutesWithNavigationApp";

import AuthRoute from './routes/AuthRoute';
import LoginPage from "./pages/login.page";

import './assets/css/app.css';
import firebaseConnector from "./api/firebase-connector";
import ConfigContext from "./context/config.context";
import ResetPasswordPage from "./pages/reset-password.page";

export default function App() {
    const [user, setUser] = useState(undefined)
    const location = useLocation()

    useEffect(()=>{
        const unsubscribe = firebaseConnector.auth().onAuthStateChanged(setUser)
        return () => {
            unsubscribe();
        };
    }, [])

    const notConnectedPathname = ["/login", "/reset-password"]

    if(user === undefined)
        return <MDBSpinner/>

    if(user === null && !notConnectedPathname.includes(location.pathname))
        return <Redirect to="/login"/>

    if(user !== null && notConnectedPathname.includes(location.pathname))
        return <Redirect to="/dashboard/stand"/>

    return(
        <Switch>
            <Route path='/login' exact component={LoginPage} />
            <Route path='/reset-password' exact component={ResetPasswordPage} />
            <AuthRoute path='/' component={RoutesWithNavigationApp} />
        </Switch>
    );
}
