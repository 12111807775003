import React, {useContext, useEffect, useState} from "react";
import {
	Backdrop,
	Button, CircularProgress,
	Dialog, DialogActions,
	DialogContent,
	DialogTitle,
	FormControl, Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField
} from "@material-ui/core";

import {MDBCol, MDBRow} from "mdbreact";
import ConfigContext from "../../context/config.context";
import axios from "axios";

import ActionContext from "../../context/action.context";
import FileInput from "./FileInput";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "../../utils/Alert";
import SimpleMarkdownEditor from "./SimpleMarkdown/SimpleMarkdownEditor";

export default function DialogStand({stand: standProp, isEdit, isOpen, handleClose}){

	//Markdown
	const [tabImages, setTabImages] = useState([]);
	const [tabImagesReaded, setTabImagesReaded] = useState([]);

	const [stand, setStand] = useState(isEdit ? standProp : {
		name: "",
		type: "sound",
		image: null,
		resFile: [],
		nfcId: ""
	})
	const [isValid, setIsValid] = useState(false)

	const [isPending, setIsPending] = useState(false)

	const {config} = useContext(ConfigContext)
	const {addStand, editStand} = useContext(ActionContext)

	useEffect(()=>{

		let valid = stand.name !== "" && stand.nfcId !== "" && stand.image !== null;

		let invalidArrayFile = []

		if (!isEdit || stand.type === "markdown") {
			invalidArrayFile = stand.resFile.filter(resData => resData.text === "" || resData.file === null || resData.file === "")
			valid = valid && stand.image !== null && invalidArrayFile.length === 0
		}else{
			let somethingChanged = standProp.name !== stand.name || standProp.type !== stand.type || standProp.nfcId !== stand.nfcId

			let filesChanged = stand.resFile.find(
				(resData, index) => typeof resData.file !== "string" || resData.text !== standProp.resFile[index].text
			) !== undefined
			valid = valid && (somethingChanged || filesChanged)

		}

		setIsValid(valid)
	}, [stand])

	useEffect(()=>{
		const resFileArray = config.i18.map(lang=>{ return {langCode: lang.code, text: "", file: ""}});

		if(!isEdit)
			setStand({
				name: "",
				type: "markdown",
				image: null,
				resFile: resFileArray,
				nfcId: ""
			})
		else
			setStand(standProp)
	}, [isEdit, isOpen, config.i18])

	const handleSubmit = async () => {
		setIsPending(true)
		let standData = {...stand};

		const upload_file = async (file) => {
			let fileFormData = new FormData();
			fileFormData.append("resFile", file);
			const {data} = await axios.post(`${config.storage.baseUrl}/storage`, fileFormData, {headers: {
					'Content-Type': 'multipart/form-data'
				}}).catch(Alert.error)
			return data.path
		}
		const delete_file = async (resPath) => {
			resPath = resPath.replace("/static/", "")
			await axios.delete(`${config.storage.baseUrl}/storage/${resPath}`).catch(Alert.error)
		}
		const delete_file_without_alert = async (resPath) => {
			resPath = resPath.replace("/static/", "")
			await axios.delete(`${config.storage.baseUrl}/storage/${resPath}`).catch()
		}

		const upload_markdown = async (resData) => {
			//On isole les index des images
			let arrayImageMarkdown = resData.file
				.split("\n")
				.filter(ligne => (ligne.slice(0,2) === "{{" && ligne.slice(-2) === "}}"))
				.map(val => val.slice(2,-2).split(":")[1])
				.filter(val => (val !== "" && val !== null && val !== undefined))
				.map(val => parseInt(val))

			//On supprime les doublons
			let uniqueSet = new Set(arrayImageMarkdown);
			arrayImageMarkdown = [...uniqueSet]

			//On upload les images et on récupère le chemin qu'on associe à l'index
			let arrayIndexLink = {}
			for(let index of arrayImageMarkdown){
				const resPath = await upload_file(tabImages[index])
				arrayIndexLink = {...arrayIndexLink, [index] : resPath}
			}

			//On remplace les occurences de l'image par le lien
			let newValueLines = resData.file.split("\n");
			for(let index of arrayImageMarkdown){
				let imageLinesArray = newValueLines
					.map(ligne => (ligne.slice(0,2) === "{{" && ligne.slice(-2) === "}}") ? ligne : null) //On filtre les lignes "image" et on met les autres à null
					.map(ligne => ligne !== null && (parseInt(ligne.slice(2,-2).split(":")[1]) === index) ? ligne : null) //On filtre pour n'avoir que les images du bon index et on met les autres à null
				//On parcours le tableau et on remplace les lignes par leur version 'online'
				imageLinesArray.forEach((ligne, index) => {
					if(ligne !== null){
						newValueLines[index] = "[[" + arrayIndexLink[ ligne.slice(2,-2).split(":")[1] ] + "]]"
					}
				})
			}

			let newValue = newValueLines.join("\n");
			return newValue;
		}

		function arr_diff (a1, a2) {

			var a = [], diff = [];

			for (var i = 0; i < a1.length; i++) {
				a[a1[i]] = true;
			}

			for (var i = 0; i < a2.length; i++) {
				if (a[a2[i]]) {
					delete a[a2[i]];
				} else {
					a[a2[i]] = true;
				}
			}

			for (var k in a) {
				diff.push(k);
			}

			return diff;
		}

		const add_submit = async () => {
			const imagePath = await upload_file(stand.image)

			let resFilePromises;

			if(stand.type === "markdown"){
				resFilePromises = stand.resFile.map(async resData => {
					var newValue = await upload_markdown(resData);

					return {...resData, file : newValue}
				})
			}else{
				resFilePromises = stand.resFile.map(async resData => {
					const resPath = await upload_file(resData.file)
					return {...resData, file: resPath}
				})
			}
			const resFileMap = await Promise.all(resFilePromises);
			standData = {...standData, ...{image: imagePath, resFile: resFileMap}};

			addStand(standData)
		}
		const edit_submit = async () => {
			if(typeof stand.image !== "string") {
				await delete_file(standProp.image)
				const imagePath = await upload_file(stand.image)
				standData = {...standData, image: imagePath}
			}

			let resFilePromises;
			if(stand.type === "markdown"){
				resFilePromises = stand.resFile.map(async (resData, key) => {

					//On récupère les url des deux markdowns
					let arrayImageNewMarkdown = resData.file
						.split("\n")
						.filter(ligne => (ligne.slice(0,2) === "[[" && ligne.slice(-2) === "]]"))
						.map(val => val.slice(2,-2))
						.filter(val => (val !== "" && val !== null && val !== undefined))
					let arrayImageMarkdown = standProp.resFile[key].file
						.split("\n")
						.filter(ligne => (ligne.slice(0,2) === "[[" && ligne.slice(-2) === "]]"))
						.map(val => val.slice(2,-2))
						.filter(val => (val !== "" && val !== null && val !== undefined))
					let suppressedFiles = arr_diff(arrayImageNewMarkdown, arrayImageMarkdown)
					console.log(suppressedFiles)
					suppressedFiles.forEach((file) => {
						delete_file_without_alert(file);
					})

					var newValue = await upload_markdown(resData);

					return {...resData, file : newValue}
				})
			}else{
				resFilePromises = stand.resFile.map(async (resData, index) => {
					if(typeof resData.file !== "string"){
						await delete_file(standProp.resFile[index].file)
						const resPath = await upload_file(resData.file)
						return {...resData, file: resPath}
					}

					return {...resData};
				})
			}

			const resFileMap = await Promise.all(resFilePromises);

			standData = {...standData, ...{resFile: resFileMap}};
			editStand(standData)
			handleClose()
		}

		if(isEdit)
			await edit_submit()
		else
			await add_submit()

		setIsPending(false)
	}

	const handleFileSelected = (file) => {
		setStand({...stand, ...{image: file}})
	}

	const handleResFileSelected = (lang) => (file) => {
		setStand({...stand, ...{resFile: stand.resFile.map(resData => resData.langCode === lang ? {...resData, file: file} : resData)}})
	}
	const handleResTextSelected = (lang) => (text) => {
		setStand({...stand, ...{resFile: stand.resFile.map(resData => resData.langCode === lang ? {...resData, text: text} : resData)}})
	}

	const handleChange = (prop) => (event) => {
		setStand({...stand, ...{[prop]: event.target.value}})
	}

	const useStyles = makeStyles((theme) => ({
		backdrop: {
			zIndex: theme.zIndex.drawer + 1,
			color: theme.palette.primary.main,
		},
		languageCode : {
			fontWeight : "bold",
			paddingLeft : "20px",
			paddingTop : "20px",
			[theme.breakpoints.down("sm")] : {
				textAlign : "center"
			}
		}
	}));
	const classes = useStyles();


	return(
		<Dialog
			fullWidth={true}
			maxWidth={"md"}
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<Backdrop className={classes.backdrop} open={isPending}>
				<CircularProgress color="inherit" />
			</Backdrop>
			<DialogTitle id="form-dialog-title">
				{isEdit ? "Modifier" : "Ajouter"} un stand
			</DialogTitle>
			<DialogContent>
				<Grid container>
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={12} sm={6}>
								<TextField
									onChange={handleChange("name")}
									value={stand.name}
									required
									margin="dense"
									label="Nom du stand"
									type="text"
									style={{marginLeft: "10%", width : "80%", marginBottom : "25px"}}
								/>

								<FormControl style={{marginLeft: "10%", width : "80%", display: "flex"}}>
									<InputLabel id="file-type">Type de fichier</InputLabel>
									<Select
										labelId="file-type-select-label"
										id="file-type-select"
										value={stand.type}
										disabled={isEdit}
										required
										onChange={
											(event)=>{
												let newResFile = [...stand.resFile];
												for(let key of Object.keys(stand.resFile)){
													newResFile[key].file = null
												}
												setStand({...stand, ...{resFile: newResFile, type: event.target.value}});
											}
										}
									>
										<MenuItem value="sound">Fichier audio</MenuItem>
										<MenuItem value="video">Fichier vidéo</MenuItem>
										<MenuItem value="markdown">Image et texte</MenuItem>
									</Select>
								</FormControl>
							</Grid>
							<Grid item xs={12} sm={6}>
								<Grid container>
									<Grid item xs={12} sm={6}>
										<p className="mr-3 h6" style={{verticalAlign: "-webkit-baseline-middle"}}>Couverture :<br/></p>
										<FileInput
											accept="image/*"
											text={stand.image !== null && stand.image !== undefined ? "Modifier l'image" : "Selectionner une image"}
											handleFileSelected={handleFileSelected}
											file={stand.image}
										/>
									</Grid>
									<Grid item xs={12} sm={6}>
										{
											(stand.image !== null && stand.image !== undefined && !stand.image.name) && (
												<div style={{padding: "15px"}}>
													<img
														src={`${config.storage.baseUrl}${stand.image}`}
														style={{
															border : "black 1px solid",
															borderRadius : "15px",
															width : "100%"
														}}
													/>
												</div>
											)
										}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
				<hr/>
				<Grid container>
					{
						stand.type === "markdown" ? (
							stand.resFile.map((resObject, index) => (
								<Grid item xs={12} style={{padding : "15px 0px"}}>
									<Grid container>
										<Grid item xs={12} md={6} className={classes.languageCode}>
											{resObject.langCode.toUpperCase()}
										</Grid>
										<Grid item xs={12} md={6}>
											<TextField
												onChange={(event)=>{handleResTextSelected(resObject.langCode)(event.target.value)}}
												value={resObject.text}
												required
												fullWidth={true}
												label={`Nom en ${config.i18.find(availableLang => availableLang.code === resObject.langCode).text}`}
												type="text"
												style={{width : "80%"}}
											/>
										</Grid>
										<SimpleMarkdownEditor
											onChange={(value) => setStand({...stand, resFile : stand.resFile.map(resData => resData.langCode === resObject.langCode ? {...resData, file : value} : resData)})}
											value={resObject.file}
											tabImages={tabImages}
											setTabImages={setTabImages}
											tabImagesReaded={tabImagesReaded}
											setTabImagesReaded={setTabImagesReaded}
										/>
									</Grid>
								</Grid>
							))
						) : (
							stand.resFile.map((resObject, index) => (
								<StandFileInput
									key={index}
									langObject={config.i18.find(availableLang => availableLang.code === resObject.langCode)}
									type={stand.type}
									resObject={resObject}
									handleResFileSelected={handleResFileSelected(resObject.langCode)}
									handleResTextSelected={handleResTextSelected(resObject.langCode)}
									config={config}
									isEdit={isEdit}
								/>
							))
						)
					}
				</Grid>
				<hr/>

				<TextField
					onChange={handleChange("nfcId")}
					value={stand.nfcId}
					required
					margin="dense"
					label="Code NFC"
					type="text"
					style={{margin : "5%", width : "90%"}}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" variant="contained" disabled={!isValid}>
					{isEdit ? "Modifier" : "Ajouter"}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

function StandFileInput({type, langObject, resObject, handleResFileSelected, handleResTextSelected, config, isEdit}){
	return(
		<Grid item xs={12} style={{paddingBottom : "20px"}}>
			<Grid container>
				<Grid item xs={12} md={1} style={{display : "flex", alignItems : "center", justifyContent : "center", fontWeight : 600, marginBottom : "15px"}}>
					<p style={{margin : "0px"}}>{langObject.code.toUpperCase()}</p>
				</Grid>
				<Grid item xs={12} md={5} style={{display : "flex", alignItems : "center", justifyContent : "center", flexDirection : "column"}}>
					<FileInput
						accept={type === "sound" ? ".mp3" : type === "video" ? ".mp4" : ""}
						text={`${resObject.file !== null && resObject.file !== undefined ? "Modifier le" : "Selectionner un"} fichier ${type === "sound" ? "mp3" : type === "video" ? "mp4" : type}`}
						handleFileSelected={handleResFileSelected}
						file={resObject.file}
					/>
					{
						(resObject && resObject.file) && (
							<>
								{(resObject.file.name && type === "sound" && isEdit) &&
								<audio
									style={{marginTop: "0.75rem"}}
									controls
									src={`${config.storage.baseUrl}${resObject.file}`}
								>
									Your browser does not support the <code>audio</code> element.
								</audio>
								}

								{(!resObject.file.name && type === "video" && isEdit) &&
								<video
									style={{width: "15vw"}}
									controls
									src={`${config.storage.baseUrl}${resObject.file}`}
								>
									Your browser does not support the
									<code>video</code> element.
								</video>
								}
							</>
						)
					}
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						onChange={(event)=>{handleResTextSelected(event.target.value)}}
						value={resObject !== undefined ? resObject.text : ""}
						required
						fullWidth={true}
						label={`Nom en ${langObject.text}`}
						type="text"
						style={{width : "80%"}}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}